<template>
  <div class="message-container">
    <div class="message-warp" v-loading="listLoading">
      <template v-if="Array.isArray(list) && list.length !== 0">
        <MessageItem v-for="(item, index) in list" @reply="handleMessageReply" :key="index" :data="item" @delete="handleMSGDelete" @editor="handleEditor" />
      </template>
      <el-empty v-else description="暂无留言"></el-empty>
    </div>
    <div class="message-input" v-loading="messageLoading" @keydown.enter="handleMessage" @keydown.delete="handleDelete">
      <el-input
        class="custom-input"
        :placeholder="placeholder"
        v-model="inputText">
        <template #prefix>
          <svg v-if="!reply" t="1653893187156" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30175" width="18" height="18"><path d="M864 160a64 64 0 0 1 64 64v512a64 64 0 0 1-64 64H521.664l-183.936 122.624a32 32 0 0 1-49.28-21.312L288 896v-96h-128a64 64 0 0 1-64-64v-512a64 64 0 0 1 64-64z m0 64h-704v512H320a32 32 0 0 1 31.488 26.24L352 768v68.096l142.272-94.72a32 32 0 0 1 11.52-4.736L512 736h352v-512zM384 416v128H320v-128h64z m160 0v128h-64v-128h64z m160 0v128h-64v-128h64z" fill="#121619" p-id="30176"></path></svg>
          <span v-else class="reply-msg">
            <span class="reply-username">@{{ reply.create_user_name }}</span>
            <span class="clearn" @click.stop="handleMessageReply(reply)">清除</span>
          </span>
        </template>
      </el-input>
    </div>
  </div>
</template>
<script>
/**
 * @description 工单留言
 */
import MessageItem from './MessageItem.vue'
import { getUserInfo } from '@/utils/tools.js'
import { dataInterface } from "@/apis/data";
import { Empty } from "element-ui";
export default {
  name: 'Message',
  components: { MessageItem, 'el-empty': Empty },
  props: {
    nodeData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      inputText: '',
      currentUser: getUserInfo(),
      list: [],
      placeholder: '输入您的评论，点击“Enter”发布',
      reply: null,
      messageLoading: false,
      listLoading: false,
      editorData: null
    }
  },
  watch: {
    nodeData: {
      handler(n) {
        if(n && n.id) {
          this.getMessageList()
        }
      },
      immediate: true
    }
  },
  methods: {
    handleMessage() {
      this.doCreate()
    },
    doUpdate() {
      const data = {
        __method_name__: 'updateData',
        object_uuid:'object62ab022cb90ba',
        content: this.inputText,
        data_id: this.editorData.id
      }
      this.messageLoading = true
      dataInterface(data).then(res => {
        if(res.data.code === 200) {
          this.inputText = ''
          if(this.reply) {
            this.reply = null
          }
          this.getMessageList()
        }
      }).catch(() => {
        // 
      }).finally(() => {
        this.messageLoading = false
      })
    },
    doCreate() {
      const data = {
        __method_name__: 'createData',
        object_uuid:'object62ab022cb90ba',
        view_uuid: 'view62ab04cb31004',
        content: this.inputText,
        todu_id: this.nodeData.id,
        to_user_id: this.reply ? this.reply.create_user_id : 0,
        to_user_name: this.reply ? this.reply.create_user_name : '',
        parent_id: this.reply ? (this.reply.parent_id ? this.reply.parent_id :this.reply.id) : 0,
        create_user_img: this.currentUser.avatar,
        create_user_name: this.currentUser.name,
        create_user_id: this.currentUser.id
      }
      this.messageLoading = true
      dataInterface(data).then(res => {
        if(res.data.code === 200) {
          this.inputText = ''
          if(this.reply) {
            this.reply = null
          }
          this.getMessageList()
        }
      }).catch(() => {
        // 
      }).finally(() => {
        this.messageLoading = false
      })
    },
    handleDelete() {
      if(this.reply && this.inputText === '') {
        this.reply = null
        this.placeholder = `输入您的评论，按下“Enter”键发布`
      }
    },
    handleMessageReply(data) {
      if(this.reply === data) {
        this.reply = null
        this.placeholder = `输入您的评论，按下“Enter”键发布`
      } else {
        this.reply = data
        this.placeholder = `输入回复,“Enter”键发布、“Backspace”键取消`
      }
    },
    handleMSGDelete(data) {
      const reqdata = {
        __method_name__: 'deleteData',
        object_uuid:'object62ab022cb90ba',
        data_id: data.id
      }
      dataInterface(reqdata).then(res => {
        if(res.data.code === 200) {
          this.getMessageList()
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        // 
      }).finally(() => {
      })
    },
    handleEditor(data) {
      console.log(data)
    },
    getMessageList() {
      const data = {
        __method_name__: 'dataList',
        object_uuid:'object62ab022cb90ba',
        view_uuid: 'view62ab04cb31004',
        transcode: 0,
        todo_id: this.nodeData.id,
      }
      this.listLoading = true
      dataInterface(data).then(res=>{
        if(res.data.code === 200){
          this.list = res.data.data
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.listLoading = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .message-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 0 0 0;
    box-sizing: border-box;
    .message-warp{
      flex-grow: 1;
      height: 10px;
      overflow: auto;
    }
    .message-input{
      border-top: 1px solid #f1f1f1;
      height: 64px;
      background: #FFFFFF;
      .custom-input{
        width: 100%;
        height: 100%;
        display: flex;
        :deep(.el-input__inner) {
          width: 100%;
          height: 100%;
          border: none;
          padding-left: 70px;
        }
        :deep(.el-input__prefix) {
          width: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          .reply-msg{
            font-size: 12px;
            color: var(--el-color-primary);
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .clearn{
              display: none;
              color: red;
            }
            .reply-username{
              display: inline-block;
            }
            &:hover{
              .clearn{
                display: inline-block;
              }
              .reply-username{
                display: none;
              }
            }
          }
        }
      }
    }
  }
</style>