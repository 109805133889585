<template>
  <div class="order-info-container" v-loading="loading">
    <div class="main">
      <div class="form-warp">
        <div class="title">
          {{ nodeData.nodeData.func_form_name }}
        </div>
        <OrderLineInfo v-if="lineData" :data="lineData"/>

        <div style="margin: 10px 20px">
          <PcParser
            v-if="formDesignData"
            ref="dialogForm"
            :form-data="formDesignData"
            :displayData="displayData"
            :editFields="editFields"
            :disabled="formDisabled"
            :needFlow="!!needFlow"
            :moduleUuid="moduleUuid"
            :formMode="formMode"
            @submit="onFormSubmit"
            @on-close="onEndFlow"
          ></PcParser>

          <TaskPicker
            v-if="taskRecoverId"
            :key="key"
            type="next"
            :taskEngineId="taskEngineId"
            :stepNode="stepNode"
            :taskRecoverId="taskRecoverId"
            @checked-users="dealWithPicker"
            @selected-step="getSelectStep">
          </TaskPicker>
        </div>
      </div>
      <div class="info-warp">
        <Tabs v-model="activeName" class="order-info-tabs">
          <TabPane label="表单动态" name="first">
            <Dynamic v-if="dynamicData" :taskTodo="dynamicData" />
          </TabPane>
          <TabPane label="留言" style="height: 100%" name="second">
            <Message :node-data="nodeData"/>
          </TabPane>
        </Tabs>
      </div>
    </div>
    <div class="action-row">
      <el-button type="primary" style="padding: 10px 34px" v-if="canSubmit" @click="triggerSubmit">
        提交表单
      </el-button>
    </div>
  </div>
</template>
<script>
/**
 * @description 共单小项详情
 */
import OrderLineInfo from '@/views/workOrder/components/orderLineInfo/index.vue'
import Dynamic from '@/views/workOrder/components/dynamic/index.vue'
import Message from '@/views/workOrder/components/message/index.vue'
import PcParser from "@/custom-component/form/newParser/PcParser";
import { Tabs, TabPane } from 'element-ui'
import {formDesign} from "@/apis/data/workflow";
import TaskPicker from "@/views/workOrder/task-flow/components/TaskPicker";
import {uniqid} from "@/plugins/util";
import {dataInterface, transFieldsUUid} from "@/apis/data";
export default{
  name: 'OrderInfo',
  components: { Tabs,  TabPane, Dynamic, OrderLineInfo, Message, PcParser,TaskPicker },
  props:['nodeData'],
  data() {
    return {
      activeName: 'first',
      formDesignData: null,
      formDisabled: false,
      displayData: {},
      editFields: {},
      needFlow: false,
      moduleUuid: '',
      formMode: 'add',
      loading: true,
      taskRecoverId: 0,
      taskEngineId: 0,
      stepNode: '',
      selectedUsers: [],
      checkPickerNum: 0,
      key: uniqid(),
      dynamicData: null,
      lineData: null,
    }
  },
  mounted() {
    this.dynamicData = [this.nodeData] // 任务动态
    this.taskEngineId = this.nodeData.task_engine_id
    this.taskRecoverId = this.nodeData.task_recover_id
    this.stepNode = this.nodeData.step_node
    this.initParser()
  },
  watch:{
    nodeData:{
      handler(){
        this.dynamicData = [this.nodeData] // 任务动态
        this.taskEngineId = this.nodeData.task_engine_id
        this.taskRecoverId = this.nodeData.task_recover_id
        this.stepNode = this.nodeData.step_node
        this.key = uniqid()
        this.initParser()
      },
      deep: true
    }
  },
  computed:{
    canSubmit(){
      const userInfo = this.$GetUserInfo()
      for (let item of this.nodeData.user_task){
        if(item.is_deal === 0 && item.user_id === userInfo.id){
          return true
        }
      }
      return false
    }
  },
  methods:{
    initParser(){
      const user = Array.from(this.nodeData.user_task,item=>item.user_name)
      const lineData = {
        func_form: this.nodeData.nodeData.func_form,
        status: this.nodeData.userWait.is_deal,
        deadline: this.nodeData.nodeData.deadline,
        target_users: user.join(',')
      }
      this.lineData = lineData

      this.formDesignData = null
      this.loading = true
      const formType = this.nodeData?.nodeData?.form_type || 1
      if(formType === 1){
        formDesign.formShow(this.nodeData.nodeData.func_form).then(res=>{
          if(res.data.code === 200){
            const formData = res.data.data
            this.formDesignData = JSON.parse(formData.json)
            this.needFlow = !!formData.need_flow
            this.moduleUuid = formData.module_uuid
            this.loading = false
          }
        });
      } else if(formType === 2){
        const objectUuid = this.nodeData?.nodeData?.object_uuid
        const formUuid = this.nodeData?.nodeData?.func_form
        transFieldsUUid(objectUuid, formUuid).then((res) => {
          if (res.data.code == 200) {
            this.needFlow = !!res.data.data.need_flow;
            this.moduleUuid = formUuid || objectUuid
            this.needParentFlow = !!res.data.data.need_parent_flow;
            this.formDesignData = res.data.data;
            this.loading = false;
          }
        })
      }
    },
    onEndFlow(){},
    triggerSubmit(){
      this.$refs.dialogForm.handleSubmit()
    },
    getSelectStep(){},
    // 任务责任人
    dealWithPicker(selected, total) {
      this.selectedUsers = selected
      this.checkPickerNum = total
    },
    onFormSubmit(formData){
      formData.task_log_id = this.nodeData?.task_log_id || 0
      if (
        this.checkPickerNum !== 0 &&
        this.checkPickerNum !== this.selectedUsers.target_users.length
      ) {
        this.$message.error('下一任务节点责任人未选择！')
        return false
      }
      if (this.selectedUsers.target_users && this.selectedUsers.target_users.length > 0) {
        formData.target_users = this.selectedUsers.target_users
      }
      if(this.nodeData.nodeData.form_type === 1){
        formData.module_uuid = this.moduleUuid
        formDesign.formDataStore(formData).then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
            this.$emit('refresh')
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else if(this.nodeData.nodeData.form_type === 2){
        const objectUuid = this.nodeData?.nodeData?.object_uuid
        const params = {
          __method_name__: 'createData',
          object_uuid: objectUuid,
          ...formData
        }
        dataInterface(params)
          .then((res) => {
            if (res.data.code=== 200) {
              this.$emit('refresh')
            }
          }).catch((err) => {
            console.log(err);
          }).finally(()=>{
            this.$loading().close();
          });
      }
    },
  }
}
</script>
<style lang="less" scoped>
  .order-info-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    .main{
      flex-grow: 1;
      height: 10px;
      display: flex;
      .form-warp{
        flex-grow: 1;
        width: 10px;
        height: 100%;
        overflow-y: auto;
        .title{
          padding: 12px 24px;
          box-sizing: border-box;
          font-size: 14px;
          font-family: var(--systemFontFamily);
          font-weight: 500;
          color: #272829;
          line-height: 22px;
        }
      }
      .info-warp{
        width: 392px;
        height: 100%;
        border-left: 1px solid #f1f1f1;
        .order-info-tabs{
          height: 100%;
          :deep(.el-tabs__header) {
            padding: 0 10px;
            box-sizing: border-box;
          }
          :deep(.el-tabs__nav-wrap::after) {
            height: 0;
          }
          :deep(.el-tabs__content) {
            height: calc(100% - 55px);
            overflow: auto;
          }
        }
      }
    }
    .action-row{
      width: 100%;
      height: 64px;
      padding: 0 24px;
      border-top: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
    }
  }
</style>
